class UpdateCustomer extends HTMLElement {
  constructor() {
    super();

    this.customerId = window.PxlApp.customer?.id;
    this.timestamp = this.getAttribute('timestamp');
    this.signature = this.getAttribute('signature');
    this.shopDomain = this.getAttribute('shop-domain');
  }

  updateTimestamp = async parameters => {
    const apiDomain = window.PxlApp.apiDomain;
    const apiURL = `https://${apiDomain}/customers/${this.customerId}`;

    try {
      const response = await fetch(apiURL, {
        method: 'put',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Medaire-Signature': this.signature
        },
        body: JSON.stringify(parameters)
      });

      if (response.ok && this.successCallback) {
        this.successCallback();
      }
    } catch (error) {
      console.error(error);
    }
  };

  successCallback() {}
}

export default UpdateCustomer;
